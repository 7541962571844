<template>
  <surround-box title="土地承载力">
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">单位：{{ unit }}</div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [],
      unit: "",
    };
  },
  methods: {
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.data.map((item) => item.years),
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#fff",
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#fff"],
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            data: this.data.map((item) => item.num),
            barWidth: 30,
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#BAFF7D",
                },
                {
                  offset: 1,
                  color: "#23A6F2",
                },
              ]),
            },
          },
        ],
      };
      chart.setOption(options);
    },
    getStatics() {
      this.$post('statistics/capacityByNum', {
        resourceTypeId: 158,
      }).then((res) => {
        this.data = res;
        this.initChart();
        this.unit = '头猪/亩';
      });
    },
  },
  mounted() {
    this.getStatics();
  },
};
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 230px;
  position: relative;
  .chart {
    width: 100%;
    height: 200px;
  }
  .unit {
    position: absolute;
    left: 16px;
    top: 12px;
    color: #fff;
    font-weight: bold;
  }
}
</style>
